// src/components/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '100',
                width: '80%',
                margin: 'auto',

            }}>
            <h1>Privacy Policy</h1>
            <p>
                Last updated: 2 October 2023
            </p>

            <p>
                At Echo MSG, we respect your personal information and, as such, have created this Privacy Policy to explain how we collect, use, and store your personal information.
            </p>

            <h2>Contact Us</h2>
            <p>
                If you have questions about this Privacy Policy, please contact us at:
                <br />
                Email: EchoMSGTeam@gmail.com
                <br />
                Phone: +44 7897 0123 69
            </p>

            <h2>Information We Collect</h2>
            <p>
                While using Echo MSG, we might ask you to provide certain personal information that can be used to contact or identify you. Personal identifiable information may include, but is not limited to, your name and email address.
            </p>

            <h2>Log Data</h2>
            <p>
                Like many site operators, we collect information that your browser sends whenever you visit Echo MSG. This log data may include information such as your computer's Internet Protocol (IP) address, browser type, browser version, the pages of Echo MSG that you visit, the time and date of your visit, and other statistics.
            </p>

            <h2>Use of Collected Information</h2>
            <p>
                Any information we collect from you may be used in the following ways:
                <ul>
                    <li>To personalize your experience and better respond to your individual needs.</li>
                    <li>To improve Echo MSG based on feedback and information we receive from you.</li>
                    <li>To improve customer service and more effectively respond to your support needs.</li>
                    <li>To process transactions.</li>
                </ul>
            </p>

            <h2>Message Privacy</h2>
            <p>
                We take your privacy seriously. Echo MSG does <strong>not</strong> store any of your messages. Our primary goal is to provide you with a seamless communication experience, without compromising your privacy.
            </p>

            <h2>Cookies</h2>
            <p>
                Cookies are files with small amounts of data, which may include an anonymous unique identifier. Like many sites, we use cookies to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
            </p>

            <h2>Changes To This Privacy Policy</h2>
            <p>
                Echo MSG may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on Echo MSG. You are advised to review this Privacy Policy periodically for any changes.
            </p>

            <h2>Consent</h2>
            <p>
                By using Echo MSG, you hereby consent to our Privacy Policy and agree to its terms.
            </p>

        </div >
    );
};

export default PrivacyPolicy;
